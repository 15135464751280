import React from "react";
import "./FAQ.css";
import { Link } from "react-scroll";

function FAQ() {
	return (
		<div id="faq" className="faq-container">
			<h1 className="faq-header">FAQ</h1>
			<details>
				<summary>What is Frame?</summary>
				<div className="drop-down">
					trading game = NFT powered interoperability and future proofing
				</div>
			</details>
			<details>
				<summary>What are early access perks?</summary>
				<div className="drop-down">
					All beta participants recieve a Genesis Pass that unlocks evolving
					benefits as Frame grows. Benefits will be revealed after beta testing
					concludes.
				</div>
			</details>
			<details>
				<summary>Where can I view & trade scenes?</summary>
				<div className="drop-down">
					During the Closed Beta, purchased Scenes are viewable and tradeable on
					OpenSea, Rarible, and other third-party marketplaces. An in-house
					marketplace and portfolio is in development.
				</div>
			</details>
			<details>
				<summary>Is Frame eco-friendly?</summary>
				<div className="drop-down">
					Frame is built on the Polygon Network. View Polygon's carbon-negative
					pledge{" "}
					<a
						className="faq-link"
						href="https://polygon.technology/sustainability"
					>
						here.
					</a>
				</div>
			</details>
			<details>
				<summary>What can I do with my Scenes?</summary>
				<div className="drop-down">
					Scenes can be used, copied, and displayed for non-commercial use. The
					complete Terms of Service can be viewed{" "}
					<a
						className="faq-link"
						href="https://docs.google.com/document/d/12qTQY4OH0Lci3ICT8-mwPazUqj-ls1RWONdZfCXp_5Y/edit?usp=sharing"
					>
						here.
					</a>
				</div>
			</details>
			{/* <span className="tos-link">
				<a className="tos-link" href="https://www.discoverframe.com/terms">
					Terms of Service
				</a>
			</span> */}
		</div>
	);
}

export default FAQ;
