import React, { useState } from "react";
import Logo from "./images/framelogo.png";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
	const [colorChange, setColorchange] = useState(false);
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setColorchange(true);
		} else {
			setColorchange(false);
		}
	};
	window.addEventListener("scroll", changeNavbarColor);

	return (
		<>
			<nav className={colorChange ? "navbar colorChange" : "navbar"}>
				<div className="logo-spacer">
					<div className="logo-container">
						<Link to="/">
							<img className="navbar-logo" src={Logo} alt="" />
						</Link>
					</div>
				</div>
				<div className="navbar-container">
					<span className={colorChange ? "blacktext colorChange" : "blacktext"}>
						SECRET SHOP
					</span>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
