import React from "react";
import { Link } from "react-scroll";
import "../App.css";
import Navbar from "./Navbar";
import Hamburger from "./Hamburger";
import "./HeroSection.css";
// import Header1 from "./images/h1-105.png";
import TAicon from "./images/taiconbeta-102.png";
import MobileBG from "./images/mobile-bg-107.png";

function HeroSection() {
	return (
		<div id="hero" className="hero-container">
			<img className="MobileBG" src={MobileBG} alt="" />

			<video autoPlay loop muted id="video">
				<source
					src="https://websiteassetsbucket.s3.us-west-1.amazonaws.com/bg-video-2.0.1.mp4"
					type="video/mp4"
				/>
			</video>
			{/* <div className="darkenlayer"></div> */}
			<div className="heroflexes">
				<div className="heroflex1">
					<div className="herotextbox">
						<h1>Collect Scenes from your favorite films.</h1>
						{/* <h2></h2> */}
						<h3>
							Collect, view, and trade Scenes from iconic films & TV shows and
							unlock VIP experiences & rewards.
						</h3>
						{/* <Link
					to="about"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="bigbutton shine-button"
				>
					<span className="bigbuttontext">Learn More</span>
				</Link> */}
					</div>
				</div>
				<div className="heroflex2">
					<Link to="about" spy={true} smooth={true} offset={0} duration={500}>
						<img className="taicon" src={TAicon} alt="" />
					</Link>
				</div>
			</div>
			<div className="burger-replacement">
				<Hamburger />
			</div>
			<div className="nav-replacement">
				<Navbar />
			</div>
		</div>
	);
}

export default HeroSection;
