import React from "react";
import "../About.css";

import { useContract, useClaimedNFTSupply } from "@thirdweb-dev/react";

function PimpCount() {
	const contractAddress = "0x1cE1De08D1690c332c7ef9ebd7b8BaB99bA74e08";
	const { contract } = useContract(contractAddress);
	const { data: claimedNFTSupply } = useClaimedNFTSupply(contract);
	return (
		<>
			<span className="claimed">{claimedNFTSupply?.toNumber()}/25 Claimed</span>
		</>
	);
}

export default PimpCount;
