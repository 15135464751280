import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Waitlist.css";
import { API } from "aws-amplify";
import { createCandidate } from "../graphql/mutations.js";

//Define form values
const initialValues = {
	name: "",
	email: "",
	message: "",
};
//End

//Validate if forms are filled
const validationSchema = Yup.object({
	name: Yup.string().required("Required"),
	email: Yup.string().required("Required"),
	message: Yup.string().required("Required"),
});
//End

function Waitlist() {
	const [formValues] = useState(null);

	const onSubmit = async (values, submitProps) => {
		await API.graphql({
			query: createCandidate,
			variables: {
				input: {
					email: values.email,
					name: values.name,
					message: values.message,
				},
			},
		});
		console.log("Form data", values);
		console.log("submitProps", submitProps);
		submitProps.setSubmitting(false);
		submitProps.resetForm();
	};

	return (
		<div id="waitlist" className="form-container">
			<div className="form-subcontainer-2">
				{/* <h1 className="form-header">Waitlist</h1> */}
				<h2>We're just getting</h2>
				<h4>started.</h4>
				<ul>
					<li>
						Join the closed beta waitlist & stay up to date on future NFT drops
						from fan-favorite films and TV shows.
					</li>
					{/* <li>.</li> */}
					{/* <li>Be considered for the Closed Beta.</li> */}
				</ul>
				{/* <img className="waitlist-img" src={WaitlistIMG} alt="" /> */}
				{/* <p className="waitlist-text">
				Join the waitlist for a chance to enter the Toxic Avenger Closed Beta.
			</p> */}
				<Formik
					initialValues={formValues || initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{(formik) => {
						console.log("Formik props", formik);
						return (
							<Form>
								<div className="form-control">
									<Field
										type="name"
										id="name"
										name="name"
										className="name-field"
										placeholder="Name"
									/>
								</div>

								<div className="form-control">
									<Field
										type={"email"}
										id="email"
										name="email"
										className="email-field"
										placeholder="Email Address"
									/>
								</div>

								<div className="form-control">
									<Field
										type={"message"}
										id="message"
										name="message"
										className="name-field"
										placeholder="Phone Number"
									/>
								</div>

								<button
									type="submit"
									className="waitlist-button shine-button"
									disabled={
										!formik.dirty || !formik.isValid || formik.isSubmitting
									}
								>
									Join Waitlist
								</button>
							</Form>
						);
					}}
				</Formik>
				{/* <p className="entry-count">Entries: {count}</p> */}
			</div>
			{/* <div className="form-subcontainer-1">
				<h2>We're just getting started.</h2>
				<ul>
					<li>
						Stay up to date about future NFT drops from fan-favorite films and
						TV shows.
					</li>
	
				</ul>
			</div> */}
		</div>
	);
}

export default Waitlist;
