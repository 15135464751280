import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import Troma from "./components/pages/Troma";
import Buy from "./components/pages/Buy";
import OnboardingFinished from "./components/OnboardingFinished";
import Terms from "./components/Terms";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import config from "../src/aws-exports";

import { ThirdwebProvider, ChainId } from "@thirdweb-dev/react";

Amplify.configure(config);
Amplify.configure(awsExports);

require("dotenv").config();

function App() {
	const desiredChainId = ChainId.Polygon;

	return (
		<ThirdwebProvider desiredChainId={desiredChainId}>
			<Router>
				<Routes>
					<Route path="/terms" element={<Terms />} />
					{/* <Route path="/secretshop" element={<Buy />} exact /> */}
					<Route path="/fulfill" element={<OnboardingFinished />} exact />
					<Route path="/troma" element={<Troma />} exact />
					{/* <Route path="/" element={<Home />} exact /> */}
					<Route path="/" element={<Navigate to="/troma" />} exact />
				</Routes>
			</Router>
		</ThirdwebProvider>
	);
}

export default App;
