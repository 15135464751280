import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import About from "../About";
import Beta from "../Beta";
import RedFooter from "../RedFooter";
import Waitlist from "../Waitlist";
import FAQ from "../FAQ";
// import Contact from "../Contact";
import Roadmap from "../Roadmap";

function Home() {
	return (
		<>
			<HeroSection />
			<About />
			<Beta />
			<Waitlist />
			<Roadmap />
			<FAQ />
			{/* <Contact /> */}
			<RedFooter />
		</>
	);
}

export default Home;
