import React from "react";
import "./RedFooter.css";
import WhiteLogo from "./images/logo_white.png";

function RedFooter() {
	return (
		<div className="redfooter-container">
			<div className="redfooter-column">
				<img className="whitelogo" src={WhiteLogo} alt="" />
				<small className="website-rights">Frame Markets, Inc. © 2022</small>
			</div>
		</div>
	);
}

export default RedFooter;
