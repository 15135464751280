import React from "react";
import { Link } from "react-scroll";
import "./Beta.css";
import TAiconAbout from "./images/taicon-about-102.png";
import TromaIMG from "./images/tromapresents.png";

function Beta() {
	return (
		<div id="beta" className="product-container">
			<div className="product-img-container">
				<img className="img2" src={TromaIMG} alt="" />
				<img className="img" src={TAiconAbout} alt="" />
			</div>
			<div className="product-text-container">
				<h2>Get Early Access Perks</h2>
				<p>
					Sign up for The Toxic Avenger® Closed Beta and claim
					ultra-limited-edition scenes from the classic film. Scenes are priced
					at $40.
				</p>
				<p>
					Purchasing two or more scenes unlocks a 10 minute video chat with
					Lloyd Kaufman, creator of the Toxic Avenger®.
				</p>
				<p>
					All beta participants recieve a Frame Genesis Pass that unlocks
					evolving benefits as Frame grows.
				</p>
				<p>The Closed Beta is invite only.</p>
				<Link
					to="waitlist"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="product-bigbutton shine-button"
				>
					<span>Join Waitlist</span>
				</Link>
			</div>
		</div>
	);
}

export default Beta;
