import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Contact.css";
import { API } from "aws-amplify";
import { createCandidate } from "../graphql/mutations.js";

//Define form values
const initialValues = {
	name: "",
	email: "",
	message: "",
};
//End

//Validate if forms are filled
const validationSchema = Yup.object({
	name: Yup.string().required("Required"),
	email: Yup.string().email("Invalid email format").required("Required"),
});
//End

function Contact() {
	const [formValues] = useState(null);

	const onSubmit = async (values, submitProps) => {
		await API.graphql({
			query: createCandidate,
			variables: {
				input: {
					name: values.name,
					email: values.email,
					message: values.message,
				},
			},
		});
		console.log("Form data", values);
		console.log("submitProps", submitProps);
		submitProps.setSubmitting(false);
		submitProps.resetForm();
	};
	//End
	return (
		<div id="contact" className="contact-container">
			<h1 className="contact-header">CONTACT US</h1>
			<Formik
				initialValues={formValues || initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{(formik) => {
					console.log("Formik props", formik);
					return (
						<Form>
							<div className="form-control">
								<Field
									type="name"
									id="name"
									name="name"
									className="name-field"
									placeholder="Name"
								/>
							</div>
							<div className="form-control">
								<Field
									type={"email"}
									id="email"
									name="email"
									className="email-field"
									placeholder="Email"
								/>
							</div>

							<div className="form-control">
								<Field
									as="textarea"
									type={"message"}
									id="message"
									name="message"
									className="message-field"
									placeholder="Message"
								/>
							</div>

							<button
								type="submit"
								className="contact-button shine-button"
								disabled={
									!formik.dirty || !formik.isValid || formik.isSubmitting
								}
							>
								Submit
							</button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}

export default Contact;
