import React from "react";
import "./Roadmap.css";

function Roadmap() {
	return (
		<div id="roadmap" className="roadmap-container">
			<div className="roadmap-subcontainer">
				<h1 className="roadmap-header">Roadmap</h1>
				<div className="roadmap-2container">
					<ul className="timeline2">
						<li className="li complete">
							<div className="status">
								<div className="tl-item-left"></div>
							</div>
						</li>
						<li className="li">
							<div className="status">
								<div className="tl-item-center"></div>
							</div>
						</li>
						<li className="li">
							<div className="status">
								<div className="tl-item-right"></div>
							</div>
						</li>
					</ul>
					<div className="roadmap-body">
						<div className="roadmap-cell">
							<h3>Closed Beta</h3>
							<h2>Winter 2022</h2>
							<p>High-Tier Scenes, Genesis Passes, & Digital Rewards.</p>
						</div>
						<div className="roadmap-cell">
							<h3>Open Beta</h3>
							<h2>Summer 2023</h2>
							<p>Scene Packs, Multiple Rarities, & Expanded Collections.</p>
						</div>
						<div className="roadmap-cell">
							<h3>Launch</h3>
							<h2>TBA</h2>
							<p>Crafting, Level Up Mechanics, & Enhanced Rewards.</p>
						</div>
					</div>
					<ul className="timeline">
						<li className="li complete">
							<div className="status">
								<div className="tl-item-left"></div>
							</div>
						</li>
						<li className="li">
							<div className="status">
								<div className="tl-item-center"></div>
							</div>
						</li>
						<li className="li">
							<div className="status">
								<div className="tl-item-right"></div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Roadmap;
