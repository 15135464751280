import React from "react";
import "../About.css";

import { useContract, useClaimedNFTSupply } from "@thirdweb-dev/react";

function GymCount() {
	const contractAddress = "0xF803D17E801Bb6E8EBcCE8646Abe62324A628a65";
	const { contract } = useContract(contractAddress);
	const { data: claimedNFTSupply } = useClaimedNFTSupply(contract);
	return (
		<>
			<span className="claimed">{claimedNFTSupply?.toNumber()}/25 Claimed</span>
		</>
	);
}

export default GymCount;
