import "./VideoCarousel.css";
import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { VideoPlayer } from "@videojs-player/react";
import "video.js/dist/video-js.css";

import GymThumbnail from "./images/thumbnailgym2.png";
import MayorThumbnail from "./images/thumbnailmayor2.png";
import PimpThumbnail from "./images/thumbnailpimp2.png";
import JoyrideThumbnail from "./images/thumbnailjoyride2.png";

import GymCount from "./inventory_counts/GymCount";
import JoyrideCount from "./inventory_counts/JoyrideCount";
import PimpCount from "./inventory_counts/PimpCount";
import MayorCount from "./inventory_counts/MayorCount";

import "./About.css";

// require("dotenv").config();

// const {
// 	REACT_APP_JOYRIDE_COUNT,
// 	REACT_APP_GYM_COUNT,
// 	REACT_APP_PIMP_COUNT,
// 	REACT_APP_MAYOR_COUNT,
// } = process.env;

const VideoCarousel = () => {
	const videoProperties = [
		{
			id: 1,
			title: "Toxie at the Gym",
			claimed: <GymCount />,
			src: "https://frame-dash-videos.s3.us-west-1.amazonaws.com/23.mp4",
			thumbnail: GymThumbnail,
		},
		{
			id: 2,
			title: "Toxie's Joyride",
			claimed: <JoyrideCount />,
			src: "https://frame-dash-videos.s3.us-west-1.amazonaws.com/21.mp4",
			thumbnail: JoyrideThumbnail,
		},
		{
			id: 3,
			title: "Toxie vs the Mayor",
			claimed: <MayorCount />,
			src: "https://frame-dash-videos.s3.us-west-1.amazonaws.com/10.mp4",
			thumbnail: MayorThumbnail,
		},
		{
			id: 4,
			title: "Toxie vs the Pimp",
			claimed: <PimpCount />,
			src: "https://frame-dash-videos.s3.us-west-1.amazonaws.com/16.mp4",
			thumbnail: PimpThumbnail,
		},
	];

	return (
		<div className="carousel-container">
			<Carousel interval={null}>
				{videoProperties.map((videoObj) => {
					return (
						<Carousel.Item interval={null} key={videoObj.id}>
							<VideoPlayer
								src={videoObj.src}
								poster={videoObj.thumbnail}
								controls
								responsive={true}
								loop={false}
								volume={0.6}
								fluid={true}
							/>
							<Carousel.Caption>
								<h3 className="video-title">
									{videoObj.title} |{" "}
									<span className="claimed">{videoObj.claimed}</span>
								</h3>
								{/* <h3>$40 Each</h3> */}
							</Carousel.Caption>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</div>
	);
};

export default VideoCarousel;
