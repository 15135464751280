import React from "react";
import "../About.css";

import { useContract, useClaimedNFTSupply } from "@thirdweb-dev/react";

function MayorCount() {
	const contractAddress = "0xbcE29dB239028E5809C819F0cB64702Fb06E26D5";
	const { contract } = useContract(contractAddress);
	const { data: claimedNFTSupply } = useClaimedNFTSupply(contract);
	return (
		<>
			<span className="claimed">{claimedNFTSupply?.toNumber()}/25 Claimed</span>
		</>
	);
}

export default MayorCount;
