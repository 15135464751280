import React from "react";
import Navbar from "./Navbar-Route-whitelogo";
import "../App.css";
import "./Onboarding.css";
// import TAIcon from "./images/taicon-about-102.png";

function Onboarding() {
	return (
		<div id="onboarding" className="onboarding-container">
			<div className="onboarding-precontainer">
				<div className="onboarding-subcontainer-1">
					<h1>Thank you</h1>
					<h2>for your purchase!</h2>
					{/* <h3>Submission Confirmed!</h3> */}
					{/* <p>We will be in touch.</p> */}
					<p className="text-diminished">
						Note: Check your 'hidden' folder on OpenSea if your Scene doesn't
						show up in your profile.
					</p>
				</div>
				{/* <div className="social-container">
					<a
						href="https://discord.gg/h9NcnZnA4E"
						className="bigbutton shine-button"
					>
						<span>Join us on Discord</span>
					</a>
				</div> */}
				<div className="social-container">
					<a
						href="https://discoverframe.com/secretshop"
						className="bigbutton shine-button"
					>
						<span>Return to Shop</span>
					</a>
				</div>
			</div>
			<div className="onboarding-subcontainer-2">
				<div className="nav-replacement">
					<Navbar />
				</div>
			</div>
		</div>
	);
}

export default Onboarding;
