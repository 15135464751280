import React from "react";
import "./About.css";

import { Link } from "react-scroll";

import VideoCarousel from "./VideoCarousel.js";

import TromaLogo from "./images/troma-logo-2.png";

function About() {
	return (
		<div id="about" className="videosection-container">
			<div className="videosectionflex1">
				<div className="videos">
					<div className="vid-car">
						<VideoCarousel />
					</div>
					<div className="vid-car-mobile">
						<VideoCarousel />
					</div>
				</div>
			</div>
			<div className="videosectionflex2">
				<div className="about-textbox">
					<h1>Collect scenes</h1>
					<p>
						Collect and trade scenes from <em>The Toxic Avenger (1984)</em>.
						Each cover art features a unique frame from the film.
					</p>
					{/* <span className="availability-1-spc">$40 Per Scene</span> */}
					{/* <Link
								to="beta"
								spy={true}
								smooth={true}
								offset={0}
								duration={500}
								className="about-bigbutton shine-button"
							>
								<span>How to Buy</span>
							</Link> */}
				</div>
				<div className="about-textbox">
					<span className="availability-1-spc2">Earn rewards</span>
					{/* <h2>from the future</h2> */}
					{/* <h3>Curated by Lloyd Kaufman and Michael Herz</h3> */}
					<p>
						Unlock ultra-limited-edition physical merchandise and VIP fan
						experiences when you hit collection milestones.
					</p>
				</div>
				<Link
					to="beta"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="about-bigbutton-2 shine-button"
				>
					<span>How to Buy</span>
				</Link>
			</div>
		</div>
	);
}

export default About;
