import React from "react";
import "./Terms.css";

function Home() {
	return (
		<div className="terms-container">
			<span className="app-header">TERMS OF SERVICE</span>
		</div>
	);
}

export default Home;
