import React from "react";
import "../../App.css";
import Footer from "../Footer";
import Inventory from "../Inventory";

function Buy() {
	return (
		<>
			<Inventory />
			<Footer />
		</>
	);
}

export default Buy;
